<template>
  <div class="ct-home content-delete">
    <div class="ct-home-content">
      <div class="container d-flex pt-5">
        <h4 class="font-weight-bold">退会</h4>
      </div>
    </div>
    <div class="author-detail mt-3">
      <div class="container d-flex justify-content-center">
        <div class="mb-3">
          <p class="text-center">退会の注意点</p>
          <p class="text-center">
            ご契約中の定期購読がある場合、退会に進めません。
          </p>
          <p class="text-center">
            退会する前にすべての定期購読を解約する必要がございます。
          </p>
        </div>
      </div>
      <div
        class="container d-flex justify-content-center"
        v-if="listOrderSub && listOrderSub.total == 0"
      >
        <b-button v-b-modal.delete-modal-acc variant="dark" class="m-auto px-5"
          >退会する</b-button
        >
      </div>
    </div>
    <!-- MODAL CONFIRM  -->
    <div>
      <b-modal id="delete-modal-acc" hide-header hide-footer>
        <div class="d-block text-center mb-5">
          <h5>退会の注意点</h5>
        </div>
        <div class="d-flex justify-content-center">
          <div class="mb-3">
            <p class="text-center">本当に退会しますか？</p>
            <p class="text-center">
              購入したコンテンツが全て削除されます。復元は一切できません
            </p>
            <p class="text-center">のでご注意ください。</p>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-50">
            <b-button
              class="mx-auto btn btn-danger w-75 back-btn"
              block
              @click="close()"
              >戻る</b-button
            >
          </div>
          <div class="justify-content-center w-50 d-flex my-auto">
            <a class="text-center" role="button" @click="deleteAcc()"
              >このまま退会する</a
            >
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "DeleteAccount",
  data() {
    return {
      urlBackend: process.env.VUE_APP_ROOT_BACKEND,
      urlRedirect: "",
    };
  },
  created() {
    const req = {
      shop_id: this.$route.params.shopId,
      type: Constants.SUBCRIPTION,
    };
    this.getListOrderSubcription(req);
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId,
    });
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    ListSettingShop() {
      if (
        this.ListSettingShop &&
        this.ListSettingShop.length > 0 &&
        this.ListSettingShop[0].url_redirect
      ) {
        this.urlRedirect = this.ListSettingShop[0].url_redirect;
      }
    },
  },
  computed: {
    ...mapGetters([
      "listOrderSub",
      "ListSettingShop",
      "error",
      "success",
      "message",
    ]),
  },
  methods: {
    ...mapActions({
      getListOrderSubcription: "getListOrderSubcription",
      getListSettingShopUser: "getListSettingShopUser",
    }),
    async deleteAcc() {
      const data = {
        shop_id: this.$route.params.shopId,
      };
      const dataReturn = await this.$store.dispatch("deleteAccount", data);
      if (dataReturn.success) {
        localStorage.removeItem(Constants.USER_TYPE_USER);
        localStorage.removeItem(Constants.TOKEN_USER);
        localStorage.removeItem(Constants.NORMAL_USER_INFO);
        localStorage.removeItem(Constants.USER_ID);
        localStorage.removeItem(Constants.EXPIRES_AT_USER);
        localStorage.removeItem(Constants.IS_REMEMBER_USER);
        localStorage.removeItem(Constants.LIST_SLAG);
        if (this.urlRedirect) {
          this.$router.push(this.urlRedirect);
        } else {
          this.$router.push({
            name: this.$route.params.shopId
              ? "login shop"
              : "login shop domain",
            params: { shopId: this.$route.params.shopId },
          });
        }
      }
    },
    close() {
      this.$bvModal.hide("delete-modal-acc");
    },
  },
};
</script>
